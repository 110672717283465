import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';

const deleteArticleURL = cs.BaseURL + '/article/delete';
const styles = (theme) => ({});

class DeleteArticle extends React.Component {
    handleDialogAgree = () => {
        if (!this.props.match.params.id) {
            window.alert('Wrong information !');
            return;
        }
        let article_id = this.props.match.params.id;
        let queryString = `${deleteArticleURL}`;

        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                id: article_id
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-news-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (!data || data.error || data.code != cs.Succeed.code) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {
                    this.props.toastManager.add('Xóa bản tin thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                }
                this.props.history.push({
                    pathname: '/article',
                    state: { reload: true }
                });
            })
            .catch(() => {});
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog
                    open={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Bạn có muốn xóa hồ sơ {this.props.match.params.id} ?
                    </DialogTitle>{' '}
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary" autoFocus>
                            Xóa
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

DeleteArticle.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(DeleteArticle));
