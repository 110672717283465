import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import FormData from 'form-data';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import cs from '../../const.js';
import moment from 'moment-timezone';
import { DropzoneArea } from 'material-ui-dropzone';

const createAgentURL = cs.BaseURL + '/article/create';

const styles = (theme) => ({});

class CreateArticle extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            description: '',
            file: null
        };

        this.handleDropZoneChange = this.handleDropZoneChange.bind(this);
    }

    handleDialogAgree = () => {
        let queryString = `${createAgentURL}`;

        const formData = new FormData();
        formData.append('title', this.state.title);
        formData.append('description', this.state.description);
        formData.append('article', this.state.file);

        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-news-token') }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                if (data && data.error && data.code != cs.Succeed) {
                    this.props.toastManager.add(JSON.stringify(data.error), {
                        appearance: 'error',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                } else {

                    this.props.toastManager.add('Đã tạo bản tin !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });
                    this.props.history.push({
                        pathname: '/article',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value
        });
    };

    handleDropZoneChange(files){
        this.setState({
            file: files[0]
        });
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
                    <DialogTitle id="create_agent_form-dialog-title">Tạo bản tin</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_article_title"
                            required={true}
                            value={this.state.title}
                            onChange={this.handleChange('title')}
                            label="Tiêu đề"
                            type="name"
                        />
                        <TextField
                            fullWidth
                            autoFocus
                            margin="dense"
                            id="create_article_description"
                            required={true}
                            value={this.state.description}
                            onChange={this.handleChange('description')}
                            label="Mô tả"
                            type="name"
                        />
                        <DialogContentText>Thumbnail</DialogContentText>{' '}
                        {/* <Grid
                            container
                            direction="row"
                            justify="fixed-start"
                            alignItems="stretch"
                            spacing={20}
                            className={classes.downloadContainer}
                            gutterBottom
                        >
                            <Grid item xs={7}>
                                <Typography variant="body1">Tải file mẫu :</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <a
                                    download="import-agents-template.zip"
                                    target="_blank"
                                    href="https://media.aidriven.goldengate.biz.vn/images/import_agent_template.zip"
                                    style={{ fontSize: 13, marginTop: 2 }}
                                >
                                    import_agents.zip
                                </a>
                            </Grid>
                        </Grid> */}
                        <Grid
                            container
                            direction="column"
                            justify="space-between"
                            alignItems="stretch"
                            spacing={1}
                            className={classes.gridContainer}
                        >
                            <Grid item gutterBottom>
                                <DropzoneArea
                                    acceptedFiles={['image/*']}
                                    dropzoneText={
                                        this.state.file && this.state.file.name
                                            ? this.state.file.name
                                            : 'Upload file ảnh'
                                    }
                                    maxFileSize={50000000}
                                    filesLimit={1}
                                    showAlerts={false}
                                    showPreviewsInDropzone={false}
                                    onChange={this.handleDropZoneChange}
                                    dropZoneClass={classes.dropZone}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Bỏ qua
                        </Button>
                        <Button onClick={this.handleDialogAgree} color="primary">
                            Tạo mới
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

CreateArticle.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(CreateArticle));
