import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import FormData from 'form-data';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { withToastManager } from 'react-toast-notifications';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Paper from '@material-ui/core/Paper';
import { DropzoneArea } from 'material-ui-dropzone';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import cs from '../../../const.js';
import moment from 'moment-timezone';
import { Typography } from '@material-ui/core';

const URL = cs.BaseURL + '/article/detail';
const updateURL = cs.BaseURL + '/article/update';
const updateContentURL = cs.BaseURL + '/article/update-content';
const approveContentURL = cs.BaseURL + '/article/approve';
const styles = (theme) => ({
    active_button: {
        marginTop: 10,
        marginLeft: 0
    }
});

function TabContainer(props) {
    return <Paper style={{ padding: 8 * 6 }}>{props.children}</Paper>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired
};
class ArticleDetail extends React.Component {
    state = {
        tabNum: 0,
        id: this.props.match.params.id,
        code: '',
        title: '',
        description: '',
        file: null,
        thumbnail_url: '',
        content: [],
        content_text: '',
        content_img: '',
        file_content: null,
        active: false
    };
    constructor(props) {
        super(props);
        this.loadData();
    }

    loadData() {
        this.setState({ id: this.props.match.params.id, loading: true });
        let queryString = `${URL}`;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                id: this.state.id
            }),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-news-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    data = data.data;
                    let contentText = this.renderContentText(data.content);
                    let contentImg = this.renderContentImg(data.content);
                    this.setState({
                        code: data.code,
                        title: data.title,
                        description: data.description,
                        thumbnail_url: data.thumbnail_url,
                        content: data.content,
                        content_text: contentText,
                        content_img: contentImg,
                        active: data.active,
                        loading: false
                    });
                } else {
                    this.setState({
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleDialogAgree = () => {
        this.setState({ id: this.props.match.params.id });

        let queryString = `${updateURL}`;

        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('title', this.state.title);
        formData.append('description', this.state.description);
        formData.append('article', this.state.file);
        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-news-token') }
        })
            .then((response) => response.json())
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                // if (!data || data.error || data.code != cs.Succeed.code) {
                //     this.props.toastManager.add(JSON.stringify(data.error), {
                //         appearance: 'error',
                //         autoDismiss: true,
                //         pauseOnHover: true
                //     });
                // }
                else {
                    this.props.toastManager.add('Cập nhật bản tin thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });

                    this.props.history.push({
                        pathname: '/article',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleUpdateContent = () => {
        this.setState({ id: this.props.match.params.id });
        let queryString = `${updateContentURL}`;
        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('content_img', this.state.file_content);
        formData.append('content_text', this.state.content_text);
        fetch(queryString, {
            method: 'POST',
            body: formData,
            headers: { Authorization: localStorage.getItem(cs.System_Code + '-news-token') }
        })
            .then((response) => {
                response.json();
            })
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                // if (!data || data.error || data.code != cs.Succeed.code) {
                //     this.props.toastManager.add(JSON.stringify(data.error), {
                //         appearance: 'error',
                //         autoDismiss: true,
                //         pauseOnHover: true
                //     });
                // }
                else {
                    this.props.toastManager.add('Cập nhật bản tin thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });

                    this.props.history.push({
                        pathname: '/article',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleApproveContent = () => {
        let queryString = `${approveContentURL}`;
        let id = this.props.match.params.id;
        fetch(queryString, {
            method: 'POST',
            body: JSON.stringify({
                id: id,
                action: this.state.active ? 'APPROVE' : 'UNAPPROVE'
            }),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-news-token')
            }
        })
            .then((response) => {
                response.json();
            })
            .then((data) => {
                if (
                    data &&
                    (data.code == cs.erAuthenticationFailed.code ||
                        data.code == cs.erAccountIsLocked.code)
                ) {
                    sessionStorage.clear();
                    window.location.reload();
                }

                // if (!data || data.error || data.code != cs.Succeed.code) {
                //     this.props.toastManager.add(JSON.stringify(data.error), {
                //         appearance: 'error',
                //         autoDismiss: true,
                //         pauseOnHover: true
                //     });
                // }
                else {
                    this.props.toastManager.add('Cập nhật bản tin thành công !', {
                        appearance: 'success',
                        autoDismiss: true,
                        pauseOnHover: true
                    });

                    this.props.history.push({
                        pathname: '/article',
                        state: { reload: true }
                    });
                }
            })
            .catch(() => {});
    };

    handleChange = (name) => (event) => {
        if (name == 'active') {
            this.setState({ active: event.target.checked });
        } else {
            this.setState({
                [name]: event.target.value
            });
        }
    };

    handleDropZoneChange = (files) => {
        this.setState({
            file: files[0]
        });
    };

    handleDropZoneContentChange = (files) => {
        this.setState({
            file_content: files[0]
        });
    };

    handleTabChange = (event, value) => {
        this.setState({ tabNum: value });
    };

    renderContent = (content) => {
        try {
            let dataList = JSON.parse(content);
            let dataRender = dataList.map((item, index) => {
                if (item.type == 'text') {
                    return <DialogContent>{item.value}</DialogContent>;
                }
                if (item.type == 'image') {
                    return <img src={item.value}></img>;
                }
            });
            return dataRender;
        } catch (error) {}
    };

    renderContentText = (content) => {
        try {
            let dataList = JSON.parse(content);
            let textList = dataList.map((item) => {
                if (item.type == 'text' && item['font-size'] == 17) return item.value;
            });
            let contentText = textList.join('');
            return contentText;
        } catch (error) {}
    };

    renderContentImg = (content) => {
        try {
            let dataList = JSON.parse(content);
            let imgList = dataList.map((item) => {
                if (item.type == 'image') return item.value;
            });
            let contentImg = imgList.join('\n');
            return contentImg;
        } catch (error) {}
    };

    render() {
        const { classes } = this.props;
        const { tabNum } = this.state;

        var user_role = '';
        var user = localStorage.getItem(cs.System_Code + '-news-user');   
        if (user) user_role = JSON.parse(String(user)).role;

        return (
            <div>
                <Dialog open={true} aria-labelledby="form-dialog-title" fullWidth maxWidth="md">
                    <DialogTitle id="edit_form-dialog-title">Cập nhật bản tin</DialogTitle>
                    <DialogContent>
                        <AppBar position="static">
                            <Tabs value={tabNum} onChange={this.handleTabChange}>
                                <Tab label="Giao diện ngoài" />
                                <Tab label="Nội dung chi tiết" />
                                {user_role === cs.Role_News_Leader && <Tab label="Quản lý" />}
                            </Tabs>
                        </AppBar>
                        {tabNum === 0 && (
                            <TabContainer>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_title"
                                    required={true}
                                    value={this.state.title}
                                    onChange={this.handleChange('title')}
                                    label="Tiêu đề"
                                    type="name"
                                />

                                <TextField
                                    fullWidth
                                    margin="dense"
                                    id="edit_description"
                                    required={true}
                                    value={this.state.description}
                                    onChange={this.handleChange('description')}
                                    label="Mô tả"
                                    type="name"
                                />

                                {/* <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_thumbnail"
                                    required={true}
                                    value={this.state.thumbnail_url}
                                    onChange={this.handleChange('thumbnail_url')}
                                    label="Thumbnail"
                                    type="name"
                                /> */}
                                <Typography variant="h6">Thumbnail</Typography>

                                <Typography>{this.state.thumbnail_url}</Typography>

                                <Grid item gutterBottom>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        dropzoneText={
                                            this.state.file && this.state.file.name
                                                ? this.state.file.name
                                                : 'Upload file ảnh'
                                        }
                                        maxFileSize={50000000}
                                        filesLimit={1}
                                        showAlerts={false}
                                        showPreviewsInDropzone={false}
                                        onChange={this.handleDropZoneChange}
                                        dropZoneClass={classes.dropZone}
                                    />
                                </Grid>
                            </TabContainer>
                        )}

                        {tabNum === 1 && (
                            <TabContainer>
                                {console.log(this.state.content)}
                                <Typography> {this.renderContent(this.state.content)}</Typography>

                                <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_content_text"
                                    multiline={true}
                                    required={true}
                                    value={this.state.content_text}
                                    onChange={this.handleChange('content_text')}
                                    label="Nội dung"
                                    type="name"
                                />
                                {/* <TextField
                                    fullWidth
                                    autoFocus
                                    margin="dense"
                                    id="edit_content_img"
                                    multiline={true}
                                    required={true}
                                    value={this.state.content_img}
                                    onChange={this.handleChange('content_img')}
                                    label="Thumbnail"
                                    type="name"
                                /> */}
                                <Typography variant="h6">Thumbnail</Typography>

                                <Typography>{this.state.content_img}</Typography>

                                <Grid item gutterBottom>
                                    <DropzoneArea
                                        acceptedFiles={['image/*']}
                                        dropzoneText={
                                            this.state.file_content && this.state.file_content.name
                                                ? this.state.file_content.name
                                                : 'Upload file ảnh'
                                        }
                                        maxFileSize={50000000}
                                        filesLimit={1}
                                        showAlerts={false}
                                        showPreviewsInDropzone={false}
                                        onChange={this.handleDropZoneContentChange}
                                        dropZoneClass={classes.dropZone}
                                    />
                                </Grid>
                            </TabContainer>
                        )}

                        {tabNum === 2 && (
                            <TabContainer>
                                <div fullWidth className={classes.active_button}>
                                    <Switch
                                        checked={this.state.active}
                                        value={this.state.active}
                                        onChange={this.handleChange('active')}
                                        color="primary"
                                    />
                                    {this.state.active
                                        ? 'Bản tin đang hiệu lực'
                                        : 'Vô hiệu bản tin'}
                                </div>
                            </TabContainer>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.history.goBack} color="primary">
                            Hủy
                        </Button>
                        {tabNum === 0 && (
                            <Button onClick={this.handleDialogAgree} color="primary">
                                Cập nhật
                            </Button>
                        )}
                        {tabNum === 1 && (
                            <Button onClick={this.handleUpdateContent} color="primary">
                                Cập nhật chi tiết
                            </Button>
                        )}
                        {tabNum === 2 && (
                            <Button onClick={this.handleApproveContent} color="primary">
                                Xác nhận
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

ArticleDetail.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withToastManager(withStyles(styles)(ArticleDetail));
