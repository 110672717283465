import ImageGallery from "react-image-gallery";
import React from "react";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import "react-image-gallery/styles/css/image-gallery.css";
import Typography from "@material-ui/core/Typography";
import cs from "../const.js";

const URL = cs.BaseURL + "/order/doc/list";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <Paper />;
    }
}

const styles = theme => ({});
export default withStyles(styles)(Dashboard);
