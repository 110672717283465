import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import {
    Grid,
    Table,
    VirtualTable,
    TableHeaderRow,
    PagingPanel
} from '@devexpress/dx-react-grid-material-ui';
import Paper from '@material-ui/core/Paper';
import { PagingState, SortingState, CustomPaging } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ToastProvider } from 'react-toast-notifications';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fade } from '@material-ui/core/styles/colorManipulator';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import moment from 'moment-timezone';

import cs from '../../const';
import ArticleDetail from './detail/ArticleDetail';
import CreateArticle from './CreateArticle';
import DeleteArticle from './DeleteArticle';
import Import from './Import';

const URL = cs.BaseURL + '/article/list';

function formatValue(value) {
    if (value && value.active) value.active = '';
    else value.active = 'Đã khóa';
    return value;
}

class ArticleList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title: '',
            states: [],
            districts: [],
            searchState: '',
            searchDistrict: '',
            columns: [
                { name: 'created_at_str', title: 'Ngày' },
                { name: 'title', title: 'Tiêu đề' },
                { name: 'description', title: 'Mô tả' },
                { name: 'thumbnail_url', title: 'Thumbnail URL' },
                { name: 'active', title: 'Trạng thái' },
                { name: 'action', title: ' ' }
            ],
            rows: [],
            selection: [],
            tableColumnExtensions: [
                { columnName: 'created_at_str', align: 'center', width: 150 },
                { columnName: 'title', align: 'center' },
                { columnName: 'description', align: 'center' },
                { columnName: 'thumbnail_url', align: 'center', width: 200 },
                { columnName: 'active', align: 'center', width: 150 },
                { columnName: 'action', align: 'center', width: 100 }
            ],
            pageSizes: [5, 10, 20, 30, 60],
            totalCount: 0,
            pageSize: 30,
            currentPage: 0,
            loading: true,
            sorting: [{ columnName: 'name', direction: 'asc' }],
            showImportAgent: false
        };

        this.changeCurrentPage = this.changeCurrentPage.bind(this);
        this.changePageSize = this.changePageSize.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.refreshData = this.refreshData.bind(this);

        this.handleShowImportAgent = this.handleShowImportAgent.bind(this);
        this.handleCloseImportAgent = this.handleCloseImportAgent.bind(this);

        // this.loadStates();
    }
    componentDidMount() {
        this.loadData();
        this.intervalID = setInterval(this.loadData.bind(this), 1000 * 30);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }

    changeCurrentPage(currentPage) {
        this.setState(
            {
                loading: true,
                currentPage: currentPage
            },
            () => {
                this.loadData();
            }
        );
    }

    changePageSize(pageSize) {
        this.setState(
            {
                loading: true,
                pageSize: pageSize,
                currentPage: 0
            },
            () => {
                this.loadData();
            }
        );
    }

    changeSorting(sorting) {
        this.setState(
            {
                loading: true,
                sorting: sorting
            },
            () => {
                this.loadData();
            }
        );
    }

    refreshData() {
        this.loadData();
    }

    loadData() {
        this.setState({
            loading: true
        });

        let queryString = `${URL}`;
        fetch(queryString, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: localStorage.getItem(cs.System_Code + '-news-token')
            }
        })
            .then((response) => response.json())
            .then((data) => {
                if (data && data.data) {
                    for (var i = 0; i < data.data.length; i++) {
                        data.data[i] = formatValue(data.data[i]);
                    }
                    this.setState({
                        rows: data.data,
                        totalCount: data.total_count,
                        loading: false
                    });
                } else {
                    this.setState({
                        rows: [],
                        totalCount: 0,
                        loading: false
                    });
                    if (
                        data &&
                        (data.code == cs.erAuthenticationFailed.code ||
                            data.code == cs.erAccountIsLocked.code)
                    ) {
                        sessionStorage.clear();
                        localStorage.clear();
                        window.location.reload();
                    }
                }
            })
            .catch(() => {
                this.setState({ loading: false });
            });
    }

    handleChange = (name) => (event) => {
        this.setState(
            {
                [name]: event.target.value
            }
            // () => {
            //     if (name == 'searchState') this.loadDistricts();
            //     this.loadData();
            // }
        );
    };

    handleShowImportAgent = () => {
        this.setState({ showImportAgent: true });
    };
    handleCloseImportAgent = () => {
        this.setState({ showImportAgent: false });
    };

    render() {
        const { classes } = this.props;

        const {
            rows,
            columns,
            tableColumnExtensions,
            pageSizes,
            pageSize,
            currentPage,
            totalCount,
            loading,
            sorting
        } = this.state;

        if (
            this.props.history &&
            this.props.history.location &&
            this.props.history.location.state &&
            this.props.history.location.state.reload
        ) {
            this.loadData();
            this.props.history.location.state.reload = false;
        }
        return (
            <div>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Import DSA"
                    onClick={this.handleShowImportAgent}
                >
                    <Icon>note_add</Icon>
                    <span style={{ marginLeft: 10 }}>Tạo mới</span>
                </Button>
                <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Refresh"
                    onClick={this.refreshData}
                >
                    <Icon>refresh</Icon>
                    <span style={{ marginLeft: 10 }}>Làm mới</span>
                </Button>
                {/* <Button
                    color="inherit"
                    iconStyle={{
                        height: 200,
                        width: 200,
                        fontSize: '48px'
                    }}
                    aria-label="Create New Article"
                    component={Link}
                    to={'/article/create'}
                >
                    <Icon>person_add</Icon>
                    <span style={{ marginLeft: 10 }}>Tạo mới</span>
                </Button> */}

                <Paper className={classes.grid_Container}>
                    <Grid rows={rows} columns={columns} getRowId={getRowId}>
                        <SortingState sorting={sorting} onSortingChange={this.changeSorting} />
                        <PagingState
                            currentPage={currentPage}
                            onCurrentPageChange={this.changeCurrentPage}
                            onPageSizeChange={this.changePageSize}
                            pageSize={pageSize}
                        />
                        <CustomPaging totalCount={totalCount} />
                        <VirtualTable
                            tableComponent={TableComponent}
                            columnExtensions={tableColumnExtensions}
                            cellComponent={CellComponent}
                            rowComponent={RowComponent}
                            messages={tableMessages}
                            height="700px"
                        />

                        <TableHeaderRow
                            showSortingControls
                            className={classes.grid_header}
                            rowComponent={HeaderRowComponent}
                            cellComponent={HeaderCellComponent}
                        />
                        <PagingPanel pageSizes={pageSizes} messages={pagingPanelMessages} />
                        {loading && (
                            <CircularProgress
                                style={{
                                    position: 'relative',
                                    left: '50%',
                                    top: '200px'
                                }}
                            />
                        )}
                    </Grid>
                </Paper>
                <ToastProvider autoDismissTimeout={3000} placement="bottom-center">
                    <Route exact path="/article/create" component={CreateArticle} />
                    <Route exact path="/article/detail/:id" component={ArticleDetail} />
                    <Route exact path="/article/delete/:id" component={DeleteArticle} />
                    <Import
                        open={this.state.showImportAgent}
                        onClose={this.handleCloseImportAgent}
                    />
                </ToastProvider>
            </div>
        );
    }
}

const getRowId = (row) => row.id;
ArticleList.propTypes = {
    classes: PropTypes.object.isRequired
};

const styles = (theme) => ({
    grid_Container: {
        width: '100%',
        marginTop: theme.spacing.unit * 2,
        overflowX: 'auto'
    },
    tableStriped: {
        '& tbody tr:nth-of-type(odd)': {
            backgroundColor: 'rgb(186, 207, 255)'
        }
    },
    searchPanel: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2
    },
    search_container: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    search_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: 200,
        minWidth: 120
    },
    search_select_menu: {
        width: 200
    },
    search_adr_field: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        marginTop: theme.spacing.unit,
        width: '100%',
        minWidth: 120
    }
});

class ActionCell extends React.Component {
    render() {
        return (
            <Table.Cell
                style={{
                    padding: 1,
                    justifyContent: 'center'
                }}
            >
                <span>
                    <Button
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        color="primary"
                        component={Link}
                        to={'/article/detail/' + this.props.row.id}
                    >
                        <Icon>edit</Icon>
                    </Button>
                    <Button
                        style={{
                            margin: 0,
                            padding: 0,
                            minWidth: 48
                        }}
                        color="secondary"
                        component={Link}
                        to={'/article/delete/' + this.props.row.id}
                    >
                        <Icon>remove_circle</Icon>
                    </Button>
                </span>
            </Table.Cell>
        );
    }
}

const CellComponent = (props) => {
    const { column } = props;
    if (column.name === 'action') {
        return <ActionCell {...props} />;
    }

    return (
        <Table.Cell
            {...props}
            style={{
                padding: 2
            }}
        />
    );
};

const RowComponent = (props) => {
    return (
        <Table.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 40
            }}
        />
    );
};

const HeaderRowComponent = (props) => {
    return (
        <TableHeaderRow.Row
            {...props}
            style={{
                margin: 1,
                padding: 1,
                height: 46
            }}
        />
    );
};

const HeaderCellComponent = (props) => {
    return (
        <Table.Cell
            {...props}
            style={{
                color: 'white',
                fontSize: '13px',
                fontWeight: '600',
                backgroundColor: 'rgb(63, 109, 193)',
                margin: 1,
                padding: 1,
                paddingLeft: 40,
                alignContent: 'center'
            }}
        />
    );
};

const tableMessages = {
    noData: 'Không có dữ liệu'
};

const pagingPanelMessages = {
    showAll: 'Hiển thị tất cả',
    rowsPerPage: 'Số dòng mỗi trang',
    info: '{from} đến {to} / Tổng : {count}'
};
const TableComponentBase = ({ classes, ...restProps }) => (
    <VirtualTable.Table {...restProps} className={classes.tableStriped} />
);

export const TableComponent = withStyles(styles, { name: 'TableComponent' })(TableComponentBase);

export default withRouter(withStyles(styles)(ArticleList));
